export default {
  data () {
    return {
      metrics: {}
    }
  },

  mounted () {
    this.getMetrics()
  }
}