<template>
  <div>
    <canvas style="height: 350px;" ref="accountChart"></canvas>
  </div>
</template>
<script>
import HasMetrics from '@/mixins/HasMetrics'
import HasTable from '@/mixins/HasTable'
import Chart from 'chart.js'

export default {
  name: 'BookingsByChildAccount',

  mixins: [ HasMetrics, HasTable ],

  data () {
    return {
      title: 'Assets by Account'
    }
  },

  methods: {
    mountChart () {
      new Chart(this.$refs.accountChart, this.chartConfig)
    },

    getMetrics () {
      this.$api.get(`accounts/${this.$auth.account.id}/metrics/children`)
      .then(({ data: metrics }) => {
        this.metrics = metrics
        this.mountChart()
      })
    }
  }
}
</script>
