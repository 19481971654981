<template>
  <v-container>
    <h1>Reports</h1>

    <v-row class="mt-2">
      <v-col :cols="5">
        <v-row>
          <v-col :cols="4">
            <v-menu
              ref="reportStartDate"
              v-model="reportStartDateMenu"
              :close-on-content-click="false"
              :return-value.sync="reportStartDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="$moment(reportStartDate).format('Do MMM YYYY')"
                  label="Reporting Start Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reportStartDate"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.reportStartDate.save(reportStartDate); getAllMetrics()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col :cols="4">
            <v-menu
              ref="reportEndDate"
              v-model="reportEndDateMenu"
              :close-on-content-click="false"
              :return-value.sync="reportEndDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="$moment(reportEndDate).format('Do MMM YYYY')"
                  label="Reporting End Date"
                  prepend-icon="mdi-calendar"
                  outlined
                  dense
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="reportEndDate"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.reportEndDate.save(reportEndDate); getAllMetrics()"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col :cols="4">
            <v-select
              v-model="servicePlan"
              :items="servicePlans"
              item-value="id"
              item-text="name"
              return-object
              dense
              label="Service Plan"
              outlined
              @change="getAllMetrics"
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="mt-2">
          <v-col>
            <h1 class="display-4 primary--text">{{ metrics.total_booked }}</h1>
            <h3>Service Jobs Booked</h3>
          </v-col>
          <v-col>
            <h1 class="display-4 primary--text">{{ metrics.total_collected }}</h1>
            <h3>Service Jobs Collected</h3>
          </v-col>
          <v-col>
            <h1 class="display-4 primary--text">{{ metrics.total_returned }}</h1>
            <h3>Service Jobs Returned</h3>
          </v-col>
        </v-row>
        <v-row class="mt-2">
          <v-col>
            <h1 class="display-4 primary--text">{{ $currency(metrics.total_sundries) }}</h1>
            <h3>Total Sundries</h3>
          </v-col>
          <v-col>
            <h1 class="display-4 primary--text">{{$currency(metrics.avg_sundries) }}</h1>
            <h3>Avg Sundries</h3>
          </v-col>
        </v-row>
        <v-row>
        </v-row>
      </v-col>
      <v-col :cols="7">
        <BookingsByChildAccount />
      </v-col>
    </v-row>
    <h2>Download XLSX Reports</h2>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Description</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="report in reports"
            :key="report.key"
          >
            <td>{{ report.name }}</td>
            <td>{{ report.description }}</td>
            <td>
              <v-btn
                v-if="!producedReports[report.key]"
                color="primary"
                depressed
                :loading="reportLoading === report.key"
                @click="getReport(report.key)"
                x-small
              >
                Generate Report
              </v-btn>
              <v-btn
                v-if="producedReports[report.key]"
                color="green"
                dark
                depressed
                :loading="reportLoading === report.key"
                :href="producedReports[report.key].temp_url"
                x-small
              >
                Download
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <SnackBar success ref="successBar">
      Report created successfully, please download!
    </SnackBar>
    <SnackBar error ref="errorBar">
      {{ reportError }}
    </SnackBar>
  </v-container>
</template>
<script>
import SnackBar from '@/components/SnackBar'
import BookingsByChildAccount from '@/components/reports/BookingsByChildAccount'

export default {
  name: 'Reports',

  components: {
    BookingsByChildAccount,
    SnackBar
  },

  data () {
    return {
      reportLoading: false,
      metrics: {},
      producedReports: {},
      reportError: null,
      reportStartDateMenu: false,
      reportStartDate: null,
      reportEndDateMenu: false,
      reportEndDate: null,
      servicePlan: null,
      servicePlans: []
    }
  },

  computed: {
    metricTypes () {
      return [
        'returned-jobs',
        'collected-jobs',
        'booked-jobs',
        'sundries-total'
      ]
    },

    reports () {
      return [
        { key: 'assets', name: 'Assets', description: 'A full report containing all assets within the account.' },
        { key: 'assets-one-service', name: 'Assets 1 Service', description: 'A report containing all assets that have had their first service.' },
        { key: 'service-jobs', name: 'Service Jobs', description: 'A full report containing all service jobs that have occured.' },
        { key: 'sundries-breakdown', name: 'Sundries Breakdown', description: 'A report containing details of sundries attached to each service job.' },
      ]
    }
  },

  mounted () {
    if (!this.reportStartDate) {
      this.reportStartDate = this.$moment().subtract('Days', 31).format('YYYY-MM-DD')      
    }
    if (!this.reportEndDate) {
      this.reportEndDate = this.$moment().format('YYYY-MM-DD')      
    }
    this.getAllMetrics()
    this.getServicePlans()
  },

  methods: {
    getServicePlans () {
      this.$api.get(`accounts/${this.$auth.account.id}/service-plans`, { params: { limit: 100 } })
        .then(({ data: servicePlans }) => {
          this.servicePlans = [
            ...servicePlans,
            ...[{ id: null, name: 'Show All' }]
          ]
        })
    },

    getReport (type) {
      this.reportError = null
      this.reportLoading = type
      this.$api.post(`accounts/${this.$auth.account.id}/reports/${type}`)
        .then(({ data: report }) => {
          this.checkReport(type, report)
        })
        .catch(() => {
          this.$refs.errorBar.open()
          this.reportError = 'An error was encounted when generating the report. Please try again or contact support. Error code: 500'
          this.reportLoading = false
        })
    },

    checkReport (type, report) {
      setTimeout(() => {
        this.$api.post('file-check', report)
          .then(({ data: file }) => {
            if (file.exists) {
              this.$refs.successBar.open()
              this.reportLoading = false
              this.$set(this.producedReports, type, report)
            } else {
              this.checkReport(type, report)
            }
          })
          .catch(() => {
            this.$refs.errorBar.open()
            this.reportError = 'An error was encounted when fetching the report file. Please try again or contact support. Error code: 404'
            this.reportLoading = false
          })
      }, 3000)
    },

    getAllMetrics () {
      this.metricTypes.forEach(type => {
        this.getMetrics(type)
      })
    },

    getMetrics (type) {
      this.reportLoading = type
      this.$api.get(`accounts/${this.$auth.account.id}/metrics/${type}`, {
        params: {
          start_date: this.reportStartDate,
          end_date: this.reportEndDate,
          service_plan: this.servicePlan ? this.servicePlan.id : null
        }
      })
        .then(({ data: metrics }) => {
          this.metrics = {
            ...this.metrics,
            ...metrics
          }
        })
    }
  }
}
</script>
