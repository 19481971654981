export default {
  computed: {
    chartData () {
      var datasets = []
      if (this.metrics.datasets) {
        var labels = [...Array(this.metrics.labels.length).keys()]
        this.metrics.datasets.forEach((dataset, key) => {
          var bgColors = []
          var borderColors = []
          labels.forEach(() => {
            bgColors.push(this.metrics.bg_colours[key] || this.barBgColors[key])
            borderColors.push(this.metrics.border_colours[key] || this.barBorderColors[key])
          })
          datasets.push({
            ...{
              backgroundColor: bgColors,
              borderColor: borderColors,
              borderWidth: 1
            },
            ...dataset
          })
        })
        var bgColors = []
        var borderColors = []
        labels.forEach(() => {
          bgColors.push('rgb(103, 58, 183, 0.3)')
          borderColors.push('rgb(103, 58, 183, 1)')
        })
        datasets.push({
          label: 'Total',
          backgroundColor: bgColors,
          borderColor: borderColors,
          data: [0,0,0,0,0,0,0],
          borderWidth: 1
        })
      }
      return datasets
    },

    barBgColors () {
      return [
        'rgba(0, 160, 155, 0.3)',
        'rgb(255, 148, 28, 0.3)',
        'rgb(33, 150, 243, 0.3)',
        'rgb(233, 30, 99, 0.3)'
      ]
    },

    barBorderColors () {
      return [
        'rgba(0, 160, 155, 1)',
        'rgb(255, 148, 28, 1)',
        'rgb(33, 150, 243, 1)',
        'rgb(233, 30, 99, 1)'
      ]
    },

    chartConfig () {
      return {
        type: 'bar',
        data: {
          labels: this.metrics.labels,
          datasets: this.chartData
        },
        options: {
          title: {
            display: true,
            text: this.title
          },
          legend: {
            labels: {
              filter: (item) => {
                return !item.text.includes('Total')
              }
            }
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: (tooltipItem, data) => {
                var label = data.datasets[tooltipItem.datasetIndex].label
                var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                var total = 0;
                for (var i = 0; i < data.datasets.length; i++)
                    total += data.datasets[i].data[tooltipItem.index];

                if (label === 'Total') {
                  return `${label} : ${total}`
                } else {
                  return `${label} : ${value}`
                }
              }
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                suggestedMax: 50,
                beginAtZero: true,
                stepSize: 10
              }
            }]
          }
        }
      }
    }
  }
}